import { writable } from 'svelte/store'

export const toprightText = writable('')
export const topmenuAvailable = writable(false)
export const menuSelfData = writable({})
export const socketSend = writable({})
export const toprightReconnect = writable('')
export const isAwayUiDisplayed = writable(false)
export const isFullscreen = writable(false)
export const isProd = writable(false)
export const baseDomain = writable('')
export const rightMouseDown = writable(false)
export const debugMode = writable<boolean>()
// export const dividerOffset = writable<number|object>()
export const urlFiles = writable()
export const uiWindows = writable([])
export const settings = writable({})
export const volumePercent = writable(0)
